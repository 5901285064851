import Link from 'next/link'
import React from 'react'
import styles from './Footer.module.scss'
import Image from 'next/image'
import SocialLinks from '../SocialLinks/SocialLinks'
// import SocialLinks from './common/SocialLinks/SocialLinks'

const Footer = () => {
  return (
    <div className={styles['footer-wrapper']}>
      <div className={styles['footer-links']}>
        <div className={styles['footer-group']}>
          <div className={styles['logo']}>
            <Image src='logo.svg' width={25} height={25} alt='Socommerz Logo' />
            <span>SOCOMMERZ</span>
          </div>
          <div style={{ marginLeft: 0, marginTop: 10 }}>
            <SocialLinks />
          </div>
        </div>

        <div className={styles['footer-group']}>
          <label>Solutions</label>

          <Link href='/b2b-stack'>
            <span>B2B Stack</span>
          </Link>
          <Link href='/b2c-d2c-stack'>
            <span>B2C & D2C Stack</span>
          </Link>
          <Link href='/social-commerce'>
            <span>Social Commerce Stack</span>
          </Link>
          <Link href='/generative-ai'>
            <span>Generative AI Wrappers</span>
          </Link>
          <Link href='/product-stack'>
            <span>Headless Solutions</span>
          </Link>
        </div>

        <div className={styles['footer-group']}>
          <label>Price Engine</label>

          <Link href='/eTail-data-lake'>
            <span>eTail Data Lake</span>
          </Link>
          <Link href='/headless-price-engine-ai'>
            <span>Headless Price Engine</span>
          </Link>
          <Link href='/trend-analyzer'>
            <span>Trend Analyzer</span>
          </Link>
        </div>

        <div className={styles['footer-group']}>
          <label>Head Office:</label>
          <p className={styles['head-office']}>
            UAE Suite# 1908, 19th Floor, Indigo Icon Tower, Cluster-F Jumeirah
            Lake Towers,
            <br /> P.O.Box - 634397 <br />
            Dubai, United Arab Emirates
          </p>
        </div>
      </div>

      <div className={styles['copy-right']}>
        <span>© 2023 Socommerz Technologies Private Limited</span>
      </div>
    </div>
  )
}

export default Footer
